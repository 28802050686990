// Ionicons Variables
// --------------------------

$ionicons-font-path: "../../../../../assets/fonts/ionicons/fonts" !default;
$ionicons-font-family: "Ionicons" !default;
$ionicons-version: "2.0.0" !default;
$ionicons-prefix: ion- !default;

$ionicon-var-alert: "\f101";
$ionicon-var-alert-circled: "\f100";
$ionicon-var-android-add: "\f2c7";
$ionicon-var-android-add-circle: "\f359";
$ionicon-var-android-alarm-clock: "\f35a";
$ionicon-var-android-alert: "\f35b";
$ionicon-var-android-apps: "\f35c";
$ionicon-var-android-archive: "\f2c9";
$ionicon-var-android-arrow-back: "\f2ca";
$ionicon-var-android-arrow-down: "\f35d";
$ionicon-var-android-arrow-dropdown: "\f35f";
$ionicon-var-android-arrow-dropdown-circle: "\f35e";
$ionicon-var-android-arrow-dropleft: "\f361";
$ionicon-var-android-arrow-dropleft-circle: "\f360";
$ionicon-var-android-arrow-dropright: "\f363";
$ionicon-var-android-arrow-dropright-circle: "\f362";
$ionicon-var-android-arrow-dropup: "\f365";
$ionicon-var-android-arrow-dropup-circle: "\f364";
$ionicon-var-android-arrow-forward: "\f30f";
$ionicon-var-android-arrow-up: "\f366";
$ionicon-var-android-attach: "\f367";
$ionicon-var-android-bar: "\f368";
$ionicon-var-android-bicycle: "\f369";
$ionicon-var-android-boat: "\f36a";
$ionicon-var-android-bookmark: "\f36b";
$ionicon-var-android-bulb: "\f36c";
$ionicon-var-android-bus: "\f36d";
$ionicon-var-android-calendar: "\f2d1";
$ionicon-var-android-call: "\f2d2";
$ionicon-var-android-camera: "\f2d3";
$ionicon-var-android-cancel: "\f36e";
$ionicon-var-android-car: "\f36f";
$ionicon-var-android-cart: "\f370";
$ionicon-var-android-chat: "\f2d4";
$ionicon-var-android-checkbox: "\f374";
$ionicon-var-android-checkbox-blank: "\f371";
$ionicon-var-android-checkbox-outline: "\f373";
$ionicon-var-android-checkbox-outline-blank: "\f372";
$ionicon-var-android-checkmark-circle: "\f375";
$ionicon-var-android-clipboard: "\f376";
$ionicon-var-android-close: "\f2d7";
$ionicon-var-android-cloud: "\f37a";
$ionicon-var-android-cloud-circle: "\f377";
$ionicon-var-android-cloud-done: "\f378";
$ionicon-var-android-cloud-outline: "\f379";
$ionicon-var-android-color-palette: "\f37b";
$ionicon-var-android-compass: "\f37c";
$ionicon-var-android-contact: "\f2d8";
$ionicon-var-android-contacts: "\f2d9";
$ionicon-var-android-contract: "\f37d";
$ionicon-var-android-create: "\f37e";
$ionicon-var-android-delete: "\f37f";
$ionicon-var-android-desktop: "\f380";
$ionicon-var-android-document: "\f381";
$ionicon-var-android-done: "\f383";
$ionicon-var-android-done-all: "\f382";
$ionicon-var-android-download: "\f2dd";
$ionicon-var-android-drafts: "\f384";
$ionicon-var-android-exit: "\f385";
$ionicon-var-android-expand: "\f386";
$ionicon-var-android-favorite: "\f388";
$ionicon-var-android-favorite-outline: "\f387";
$ionicon-var-android-film: "\f389";
$ionicon-var-android-folder: "\f2e0";
$ionicon-var-android-folder-open: "\f38a";
$ionicon-var-android-funnel: "\f38b";
$ionicon-var-android-globe: "\f38c";
$ionicon-var-android-hand: "\f2e3";
$ionicon-var-android-hangout: "\f38d";
$ionicon-var-android-happy: "\f38e";
$ionicon-var-android-home: "\f38f";
$ionicon-var-android-image: "\f2e4";
$ionicon-var-android-laptop: "\f390";
$ionicon-var-android-list: "\f391";
$ionicon-var-android-locate: "\f2e9";
$ionicon-var-android-lock: "\f392";
$ionicon-var-android-mail: "\f2eb";
$ionicon-var-android-map: "\f393";
$ionicon-var-android-menu: "\f394";
$ionicon-var-android-microphone: "\f2ec";
$ionicon-var-android-microphone-off: "\f395";
$ionicon-var-android-more-horizontal: "\f396";
$ionicon-var-android-more-vertical: "\f397";
$ionicon-var-android-navigate: "\f398";
$ionicon-var-android-notifications: "\f39b";
$ionicon-var-android-notifications-none: "\f399";
$ionicon-var-android-notifications-off: "\f39a";
$ionicon-var-android-open: "\f39c";
$ionicon-var-android-options: "\f39d";
$ionicon-var-android-people: "\f39e";
$ionicon-var-android-person: "\f3a0";
$ionicon-var-android-person-add: "\f39f";
$ionicon-var-android-phone-landscape: "\f3a1";
$ionicon-var-android-phone-portrait: "\f3a2";
$ionicon-var-android-pin: "\f3a3";
$ionicon-var-android-plane: "\f3a4";
$ionicon-var-android-playstore: "\f2f0";
$ionicon-var-android-print: "\f3a5";
$ionicon-var-android-radio-button-off: "\f3a6";
$ionicon-var-android-radio-button-on: "\f3a7";
$ionicon-var-android-refresh: "\f3a8";
$ionicon-var-android-remove: "\f2f4";
$ionicon-var-android-remove-circle: "\f3a9";
$ionicon-var-android-restaurant: "\f3aa";
$ionicon-var-android-sad: "\f3ab";
$ionicon-var-android-search: "\f2f5";
$ionicon-var-android-send: "\f2f6";
$ionicon-var-android-settings: "\f2f7";
$ionicon-var-android-share: "\f2f8";
$ionicon-var-android-share-alt: "\f3ac";
$ionicon-var-android-star: "\f2fc";
$ionicon-var-android-star-half: "\f3ad";
$ionicon-var-android-star-outline: "\f3ae";
$ionicon-var-android-stopwatch: "\f2fd";
$ionicon-var-android-subway: "\f3af";
$ionicon-var-android-sunny: "\f3b0";
$ionicon-var-android-sync: "\f3b1";
$ionicon-var-android-textsms: "\f3b2";
$ionicon-var-android-time: "\f3b3";
$ionicon-var-android-train: "\f3b4";
$ionicon-var-android-unlock: "\f3b5";
$ionicon-var-android-upload: "\f3b6";
$ionicon-var-android-volume-down: "\f3b7";
$ionicon-var-android-volume-mute: "\f3b8";
$ionicon-var-android-volume-off: "\f3b9";
$ionicon-var-android-volume-up: "\f3ba";
$ionicon-var-android-walk: "\f3bb";
$ionicon-var-android-warning: "\f3bc";
$ionicon-var-android-watch: "\f3bd";
$ionicon-var-android-wifi: "\f305";
$ionicon-var-aperture: "\f313";
$ionicon-var-archive: "\f102";
$ionicon-var-arrow-down-a: "\f103";
$ionicon-var-arrow-down-b: "\f104";
$ionicon-var-arrow-down-c: "\f105";
$ionicon-var-arrow-expand: "\f25e";
$ionicon-var-arrow-graph-down-left: "\f25f";
$ionicon-var-arrow-graph-down-right: "\f260";
$ionicon-var-arrow-graph-up-left: "\f261";
$ionicon-var-arrow-graph-up-right: "\f262";
$ionicon-var-arrow-left-a: "\f106";
$ionicon-var-arrow-left-b: "\f107";
$ionicon-var-arrow-left-c: "\f108";
$ionicon-var-arrow-move: "\f263";
$ionicon-var-arrow-resize: "\f264";
$ionicon-var-arrow-return-left: "\f265";
$ionicon-var-arrow-return-right: "\f266";
$ionicon-var-arrow-right-a: "\f109";
$ionicon-var-arrow-right-b: "\f10a";
$ionicon-var-arrow-right-c: "\f10b";
$ionicon-var-arrow-shrink: "\f267";
$ionicon-var-arrow-swap: "\f268";
$ionicon-var-arrow-up-a: "\f10c";
$ionicon-var-arrow-up-b: "\f10d";
$ionicon-var-arrow-up-c: "\f10e";
$ionicon-var-asterisk: "\f314";
$ionicon-var-at: "\f10f";
$ionicon-var-backspace: "\f3bf";
$ionicon-var-backspace-outline: "\f3be";
$ionicon-var-bag: "\f110";
$ionicon-var-battery-charging: "\f111";
$ionicon-var-battery-empty: "\f112";
$ionicon-var-battery-full: "\f113";
$ionicon-var-battery-half: "\f114";
$ionicon-var-battery-low: "\f115";
$ionicon-var-beaker: "\f269";
$ionicon-var-beer: "\f26a";
$ionicon-var-bluetooth: "\f116";
$ionicon-var-bonfire: "\f315";
$ionicon-var-bookmark: "\f26b";
$ionicon-var-bowtie: "\f3c0";
$ionicon-var-briefcase: "\f26c";
$ionicon-var-bug: "\f2be";
$ionicon-var-calculator: "\f26d";
$ionicon-var-calendar: "\f117";
$ionicon-var-camera: "\f118";
$ionicon-var-card: "\f119";
$ionicon-var-cash: "\f316";
$ionicon-var-chatbox: "\f11b";
$ionicon-var-chatbox-working: "\f11a";
$ionicon-var-chatboxes: "\f11c";
$ionicon-var-chatbubble: "\f11e";
$ionicon-var-chatbubble-working: "\f11d";
$ionicon-var-chatbubbles: "\f11f";
$ionicon-var-checkmark: "\f122";
$ionicon-var-checkmark-circled: "\f120";
$ionicon-var-checkmark-round: "\f121";
$ionicon-var-chevron-down: "\f123";
$ionicon-var-chevron-left: "\f124";
$ionicon-var-chevron-right: "\f125";
$ionicon-var-chevron-up: "\f126";
$ionicon-var-clipboard: "\f127";
$ionicon-var-clock: "\f26e";
$ionicon-var-close: "\f12a";
$ionicon-var-close-circled: "\f128";
$ionicon-var-close-round: "\f129";
$ionicon-var-closed-captioning: "\f317";
$ionicon-var-cloud: "\f12b";
$ionicon-var-code: "\f271";
$ionicon-var-code-download: "\f26f";
$ionicon-var-code-working: "\f270";
$ionicon-var-coffee: "\f272";
$ionicon-var-compass: "\f273";
$ionicon-var-compose: "\f12c";
$ionicon-var-connection-bars: "\f274";
$ionicon-var-contrast: "\f275";
$ionicon-var-crop: "\f3c1";
$ionicon-var-cube: "\f318";
$ionicon-var-disc: "\f12d";
$ionicon-var-document: "\f12f";
$ionicon-var-document-text: "\f12e";
$ionicon-var-drag: "\f130";
$ionicon-var-earth: "\f276";
$ionicon-var-easel: "\f3c2";
$ionicon-var-edit: "\f2bf";
$ionicon-var-egg: "\f277";
$ionicon-var-eject: "\f131";
$ionicon-var-email: "\f132";
$ionicon-var-email-unread: "\f3c3";
$ionicon-var-erlenmeyer-flask: "\f3c5";
$ionicon-var-erlenmeyer-flask-bubbles: "\f3c4";
$ionicon-var-eye: "\f133";
$ionicon-var-eye-disabled: "\f306";
$ionicon-var-female: "\f278";
$ionicon-var-filing: "\f134";
$ionicon-var-film-marker: "\f135";
$ionicon-var-fireball: "\f319";
$ionicon-var-flag: "\f279";
$ionicon-var-flame: "\f31a";
$ionicon-var-flash: "\f137";
$ionicon-var-flash-off: "\f136";
$ionicon-var-folder: "\f139";
$ionicon-var-fork: "\f27a";
$ionicon-var-fork-repo: "\f2c0";
$ionicon-var-forward: "\f13a";
$ionicon-var-funnel: "\f31b";
$ionicon-var-gear-a: "\f13d";
$ionicon-var-gear-b: "\f13e";
$ionicon-var-grid: "\f13f";
$ionicon-var-hammer: "\f27b";
$ionicon-var-happy: "\f31c";
$ionicon-var-happy-outline: "\f3c6";
$ionicon-var-headphone: "\f140";
$ionicon-var-heart: "\f141";
$ionicon-var-heart-broken: "\f31d";
$ionicon-var-help: "\f143";
$ionicon-var-help-buoy: "\f27c";
$ionicon-var-help-circled: "\f142";
$ionicon-var-home: "\f144";
$ionicon-var-icecream: "\f27d";
$ionicon-var-image: "\f147";
$ionicon-var-images: "\f148";
$ionicon-var-information: "\f14a";
$ionicon-var-information-circled: "\f149";
$ionicon-var-ionic: "\f14b";
$ionicon-var-ios-alarm: "\f3c8";
$ionicon-var-ios-alarm-outline: "\f3c7";
$ionicon-var-ios-albums: "\f3ca";
$ionicon-var-ios-albums-outline: "\f3c9";
$ionicon-var-ios-americanfootball: "\f3cc";
$ionicon-var-ios-americanfootball-outline: "\f3cb";
$ionicon-var-ios-analytics: "\f3ce";
$ionicon-var-ios-analytics-outline: "\f3cd";
$ionicon-var-ios-arrow-back: "\f3cf";
$ionicon-var-ios-arrow-down: "\f3d0";
$ionicon-var-ios-arrow-forward: "\f3d1";
$ionicon-var-ios-arrow-left: "\f3d2";
$ionicon-var-ios-arrow-right: "\f3d3";
$ionicon-var-ios-arrow-thin-down: "\f3d4";
$ionicon-var-ios-arrow-thin-left: "\f3d5";
$ionicon-var-ios-arrow-thin-right: "\f3d6";
$ionicon-var-ios-arrow-thin-up: "\f3d7";
$ionicon-var-ios-arrow-up: "\f3d8";
$ionicon-var-ios-at: "\f3da";
$ionicon-var-ios-at-outline: "\f3d9";
$ionicon-var-ios-barcode: "\f3dc";
$ionicon-var-ios-barcode-outline: "\f3db";
$ionicon-var-ios-baseball: "\f3de";
$ionicon-var-ios-baseball-outline: "\f3dd";
$ionicon-var-ios-basketball: "\f3e0";
$ionicon-var-ios-basketball-outline: "\f3df";
$ionicon-var-ios-bell: "\f3e2";
$ionicon-var-ios-bell-outline: "\f3e1";
$ionicon-var-ios-body: "\f3e4";
$ionicon-var-ios-body-outline: "\f3e3";
$ionicon-var-ios-bolt: "\f3e6";
$ionicon-var-ios-bolt-outline: "\f3e5";
$ionicon-var-ios-book: "\f3e8";
$ionicon-var-ios-book-outline: "\f3e7";
$ionicon-var-ios-bookmarks: "\f3ea";
$ionicon-var-ios-bookmarks-outline: "\f3e9";
$ionicon-var-ios-box: "\f3ec";
$ionicon-var-ios-box-outline: "\f3eb";
$ionicon-var-ios-briefcase: "\f3ee";
$ionicon-var-ios-briefcase-outline: "\f3ed";
$ionicon-var-ios-browsers: "\f3f0";
$ionicon-var-ios-browsers-outline: "\f3ef";
$ionicon-var-ios-calculator: "\f3f2";
$ionicon-var-ios-calculator-outline: "\f3f1";
$ionicon-var-ios-calendar: "\f3f4";
$ionicon-var-ios-calendar-outline: "\f3f3";
$ionicon-var-ios-camera: "\f3f6";
$ionicon-var-ios-camera-outline: "\f3f5";
$ionicon-var-ios-cart: "\f3f8";
$ionicon-var-ios-cart-outline: "\f3f7";
$ionicon-var-ios-chatboxes: "\f3fa";
$ionicon-var-ios-chatboxes-outline: "\f3f9";
$ionicon-var-ios-chatbubble: "\f3fc";
$ionicon-var-ios-chatbubble-outline: "\f3fb";
$ionicon-var-ios-checkmark: "\f3ff";
$ionicon-var-ios-checkmark-empty: "\f3fd";
$ionicon-var-ios-checkmark-outline: "\f3fe";
$ionicon-var-ios-circle-filled: "\f400";
$ionicon-var-ios-circle-outline: "\f401";
$ionicon-var-ios-clock: "\f403";
$ionicon-var-ios-clock-outline: "\f402";
$ionicon-var-ios-close: "\f406";
$ionicon-var-ios-close-empty: "\f404";
$ionicon-var-ios-close-outline: "\f405";
$ionicon-var-ios-cloud: "\f40c";
$ionicon-var-ios-cloud-download: "\f408";
$ionicon-var-ios-cloud-download-outline: "\f407";
$ionicon-var-ios-cloud-outline: "\f409";
$ionicon-var-ios-cloud-upload: "\f40b";
$ionicon-var-ios-cloud-upload-outline: "\f40a";
$ionicon-var-ios-cloudy: "\f410";
$ionicon-var-ios-cloudy-night: "\f40e";
$ionicon-var-ios-cloudy-night-outline: "\f40d";
$ionicon-var-ios-cloudy-outline: "\f40f";
$ionicon-var-ios-cog: "\f412";
$ionicon-var-ios-cog-outline: "\f411";
$ionicon-var-ios-color-filter: "\f414";
$ionicon-var-ios-color-filter-outline: "\f413";
$ionicon-var-ios-color-wand: "\f416";
$ionicon-var-ios-color-wand-outline: "\f415";
$ionicon-var-ios-compose: "\f418";
$ionicon-var-ios-compose-outline: "\f417";
$ionicon-var-ios-contact: "\f41a";
$ionicon-var-ios-contact-outline: "\f419";
$ionicon-var-ios-copy: "\f41c";
$ionicon-var-ios-copy-outline: "\f41b";
$ionicon-var-ios-crop: "\f41e";
$ionicon-var-ios-crop-strong: "\f41d";
$ionicon-var-ios-download: "\f420";
$ionicon-var-ios-download-outline: "\f41f";
$ionicon-var-ios-drag: "\f421";
$ionicon-var-ios-email: "\f423";
$ionicon-var-ios-email-outline: "\f422";
$ionicon-var-ios-eye: "\f425";
$ionicon-var-ios-eye-outline: "\f424";
$ionicon-var-ios-fastforward: "\f427";
$ionicon-var-ios-fastforward-outline: "\f426";
$ionicon-var-ios-filing: "\f429";
$ionicon-var-ios-filing-outline: "\f428";
$ionicon-var-ios-film: "\f42b";
$ionicon-var-ios-film-outline: "\f42a";
$ionicon-var-ios-flag: "\f42d";
$ionicon-var-ios-flag-outline: "\f42c";
$ionicon-var-ios-flame: "\f42f";
$ionicon-var-ios-flame-outline: "\f42e";
$ionicon-var-ios-flask: "\f431";
$ionicon-var-ios-flask-outline: "\f430";
$ionicon-var-ios-flower: "\f433";
$ionicon-var-ios-flower-outline: "\f432";
$ionicon-var-ios-folder: "\f435";
$ionicon-var-ios-folder-outline: "\f434";
$ionicon-var-ios-football: "\f437";
$ionicon-var-ios-football-outline: "\f436";
$ionicon-var-ios-game-controller-a: "\f439";
$ionicon-var-ios-game-controller-a-outline: "\f438";
$ionicon-var-ios-game-controller-b: "\f43b";
$ionicon-var-ios-game-controller-b-outline: "\f43a";
$ionicon-var-ios-gear: "\f43d";
$ionicon-var-ios-gear-outline: "\f43c";
$ionicon-var-ios-glasses: "\f43f";
$ionicon-var-ios-glasses-outline: "\f43e";
$ionicon-var-ios-grid-view: "\f441";
$ionicon-var-ios-grid-view-outline: "\f440";
$ionicon-var-ios-heart: "\f443";
$ionicon-var-ios-heart-outline: "\f442";
$ionicon-var-ios-help: "\f446";
$ionicon-var-ios-help-empty: "\f444";
$ionicon-var-ios-help-outline: "\f445";
$ionicon-var-ios-home: "\f448";
$ionicon-var-ios-home-outline: "\f447";
$ionicon-var-ios-infinite: "\f44a";
$ionicon-var-ios-infinite-outline: "\f449";
$ionicon-var-ios-information: "\f44d";
$ionicon-var-ios-information-empty: "\f44b";
$ionicon-var-ios-information-outline: "\f44c";
$ionicon-var-ios-ionic-outline: "\f44e";
$ionicon-var-ios-keypad: "\f450";
$ionicon-var-ios-keypad-outline: "\f44f";
$ionicon-var-ios-lightbulb: "\f452";
$ionicon-var-ios-lightbulb-outline: "\f451";
$ionicon-var-ios-list: "\f454";
$ionicon-var-ios-list-outline: "\f453";
$ionicon-var-ios-location: "\f456";
$ionicon-var-ios-location-outline: "\f455";
$ionicon-var-ios-locked: "\f458";
$ionicon-var-ios-locked-outline: "\f457";
$ionicon-var-ios-loop: "\f45a";
$ionicon-var-ios-loop-strong: "\f459";
$ionicon-var-ios-medical: "\f45c";
$ionicon-var-ios-medical-outline: "\f45b";
$ionicon-var-ios-medkit: "\f45e";
$ionicon-var-ios-medkit-outline: "\f45d";
$ionicon-var-ios-mic: "\f461";
$ionicon-var-ios-mic-off: "\f45f";
$ionicon-var-ios-mic-outline: "\f460";
$ionicon-var-ios-minus: "\f464";
$ionicon-var-ios-minus-empty: "\f462";
$ionicon-var-ios-minus-outline: "\f463";
$ionicon-var-ios-monitor: "\f466";
$ionicon-var-ios-monitor-outline: "\f465";
$ionicon-var-ios-moon: "\f468";
$ionicon-var-ios-moon-outline: "\f467";
$ionicon-var-ios-more: "\f46a";
$ionicon-var-ios-more-outline: "\f469";
$ionicon-var-ios-musical-note: "\f46b";
$ionicon-var-ios-musical-notes: "\f46c";
$ionicon-var-ios-navigate: "\f46e";
$ionicon-var-ios-navigate-outline: "\f46d";
$ionicon-var-ios-nutrition: "\f470";
$ionicon-var-ios-nutrition-outline: "\f46f";
$ionicon-var-ios-paper: "\f472";
$ionicon-var-ios-paper-outline: "\f471";
$ionicon-var-ios-paperplane: "\f474";
$ionicon-var-ios-paperplane-outline: "\f473";
$ionicon-var-ios-partlysunny: "\f476";
$ionicon-var-ios-partlysunny-outline: "\f475";
$ionicon-var-ios-pause: "\f478";
$ionicon-var-ios-pause-outline: "\f477";
$ionicon-var-ios-paw: "\f47a";
$ionicon-var-ios-paw-outline: "\f479";
$ionicon-var-ios-people: "\f47c";
$ionicon-var-ios-people-outline: "\f47b";
$ionicon-var-ios-person: "\f47e";
$ionicon-var-ios-person-outline: "\f47d";
$ionicon-var-ios-personadd: "\f480";
$ionicon-var-ios-personadd-outline: "\f47f";
$ionicon-var-ios-photos: "\f482";
$ionicon-var-ios-photos-outline: "\f481";
$ionicon-var-ios-pie: "\f484";
$ionicon-var-ios-pie-outline: "\f483";
$ionicon-var-ios-pint: "\f486";
$ionicon-var-ios-pint-outline: "\f485";
$ionicon-var-ios-play: "\f488";
$ionicon-var-ios-play-outline: "\f487";
$ionicon-var-ios-plus: "\f48b";
$ionicon-var-ios-plus-empty: "\f489";
$ionicon-var-ios-plus-outline: "\f48a";
$ionicon-var-ios-pricetag: "\f48d";
$ionicon-var-ios-pricetag-outline: "\f48c";
$ionicon-var-ios-pricetags: "\f48f";
$ionicon-var-ios-pricetags-outline: "\f48e";
$ionicon-var-ios-printer: "\f491";
$ionicon-var-ios-printer-outline: "\f490";
$ionicon-var-ios-pulse: "\f493";
$ionicon-var-ios-pulse-strong: "\f492";
$ionicon-var-ios-rainy: "\f495";
$ionicon-var-ios-rainy-outline: "\f494";
$ionicon-var-ios-recording: "\f497";
$ionicon-var-ios-recording-outline: "\f496";
$ionicon-var-ios-redo: "\f499";
$ionicon-var-ios-redo-outline: "\f498";
$ionicon-var-ios-refresh: "\f49c";
$ionicon-var-ios-refresh-empty: "\f49a";
$ionicon-var-ios-refresh-outline: "\f49b";
$ionicon-var-ios-reload: "\f49d";
$ionicon-var-ios-reverse-camera: "\f49f";
$ionicon-var-ios-reverse-camera-outline: "\f49e";
$ionicon-var-ios-rewind: "\f4a1";
$ionicon-var-ios-rewind-outline: "\f4a0";
$ionicon-var-ios-rose: "\f4a3";
$ionicon-var-ios-rose-outline: "\f4a2";
$ionicon-var-ios-search: "\f4a5";
$ionicon-var-ios-search-strong: "\f4a4";
$ionicon-var-ios-settings: "\f4a7";
$ionicon-var-ios-settings-strong: "\f4a6";
$ionicon-var-ios-shuffle: "\f4a9";
$ionicon-var-ios-shuffle-strong: "\f4a8";
$ionicon-var-ios-skipbackward: "\f4ab";
$ionicon-var-ios-skipbackward-outline: "\f4aa";
$ionicon-var-ios-skipforward: "\f4ad";
$ionicon-var-ios-skipforward-outline: "\f4ac";
$ionicon-var-ios-snowy: "\f4ae";
$ionicon-var-ios-speedometer: "\f4b0";
$ionicon-var-ios-speedometer-outline: "\f4af";
$ionicon-var-ios-star: "\f4b3";
$ionicon-var-ios-star-half: "\f4b1";
$ionicon-var-ios-star-outline: "\f4b2";
$ionicon-var-ios-stopwatch: "\f4b5";
$ionicon-var-ios-stopwatch-outline: "\f4b4";
$ionicon-var-ios-sunny: "\f4b7";
$ionicon-var-ios-sunny-outline: "\f4b6";
$ionicon-var-ios-telephone: "\f4b9";
$ionicon-var-ios-telephone-outline: "\f4b8";
$ionicon-var-ios-tennisball: "\f4bb";
$ionicon-var-ios-tennisball-outline: "\f4ba";
$ionicon-var-ios-thunderstorm: "\f4bd";
$ionicon-var-ios-thunderstorm-outline: "\f4bc";
$ionicon-var-ios-time: "\f4bf";
$ionicon-var-ios-time-outline: "\f4be";
$ionicon-var-ios-timer: "\f4c1";
$ionicon-var-ios-timer-outline: "\f4c0";
$ionicon-var-ios-toggle: "\f4c3";
$ionicon-var-ios-toggle-outline: "\f4c2";
$ionicon-var-ios-trash: "\f4c5";
$ionicon-var-ios-trash-outline: "\f4c4";
$ionicon-var-ios-undo: "\f4c7";
$ionicon-var-ios-undo-outline: "\f4c6";
$ionicon-var-ios-unlocked: "\f4c9";
$ionicon-var-ios-unlocked-outline: "\f4c8";
$ionicon-var-ios-upload: "\f4cb";
$ionicon-var-ios-upload-outline: "\f4ca";
$ionicon-var-ios-videocam: "\f4cd";
$ionicon-var-ios-videocam-outline: "\f4cc";
$ionicon-var-ios-volume-high: "\f4ce";
$ionicon-var-ios-volume-low: "\f4cf";
$ionicon-var-ios-wineglass: "\f4d1";
$ionicon-var-ios-wineglass-outline: "\f4d0";
$ionicon-var-ios-world: "\f4d3";
$ionicon-var-ios-world-outline: "\f4d2";
$ionicon-var-ipad: "\f1f9";
$ionicon-var-iphone: "\f1fa";
$ionicon-var-ipod: "\f1fb";
$ionicon-var-jet: "\f295";
$ionicon-var-key: "\f296";
$ionicon-var-knife: "\f297";
$ionicon-var-laptop: "\f1fc";
$ionicon-var-leaf: "\f1fd";
$ionicon-var-levels: "\f298";
$ionicon-var-lightbulb: "\f299";
$ionicon-var-link: "\f1fe";
$ionicon-var-load-a: "\f29a";
$ionicon-var-load-b: "\f29b";
$ionicon-var-load-c: "\f29c";
$ionicon-var-load-d: "\f29d";
$ionicon-var-location: "\f1ff";
$ionicon-var-lock-combination: "\f4d4";
$ionicon-var-locked: "\f200";
$ionicon-var-log-in: "\f29e";
$ionicon-var-log-out: "\f29f";
$ionicon-var-loop: "\f201";
$ionicon-var-magnet: "\f2a0";
$ionicon-var-male: "\f2a1";
$ionicon-var-man: "\f202";
$ionicon-var-map: "\f203";
$ionicon-var-medkit: "\f2a2";
$ionicon-var-merge: "\f33f";
$ionicon-var-mic-a: "\f204";
$ionicon-var-mic-b: "\f205";
$ionicon-var-mic-c: "\f206";
$ionicon-var-minus: "\f209";
$ionicon-var-minus-circled: "\f207";
$ionicon-var-minus-round: "\f208";
$ionicon-var-model-s: "\f2c1";
$ionicon-var-monitor: "\f20a";
$ionicon-var-more: "\f20b";
$ionicon-var-mouse: "\f340";
$ionicon-var-music-note: "\f20c";
$ionicon-var-navicon: "\f20e";
$ionicon-var-navicon-round: "\f20d";
$ionicon-var-navigate: "\f2a3";
$ionicon-var-network: "\f341";
$ionicon-var-no-smoking: "\f2c2";
$ionicon-var-nuclear: "\f2a4";
$ionicon-var-outlet: "\f342";
$ionicon-var-paintbrush: "\f4d5";
$ionicon-var-paintbucket: "\f4d6";
$ionicon-var-paper-airplane: "\f2c3";
$ionicon-var-paperclip: "\f20f";
$ionicon-var-pause: "\f210";
$ionicon-var-person: "\f213";
$ionicon-var-person-add: "\f211";
$ionicon-var-person-stalker: "\f212";
$ionicon-var-pie-graph: "\f2a5";
$ionicon-var-pin: "\f2a6";
$ionicon-var-pinpoint: "\f2a7";
$ionicon-var-pizza: "\f2a8";
$ionicon-var-plane: "\f214";
$ionicon-var-planet: "\f343";
$ionicon-var-play: "\f215";
$ionicon-var-playstation: "\f30a";
$ionicon-var-plus: "\f218";
$ionicon-var-plus-circled: "\f216";
$ionicon-var-plus-round: "\f217";
$ionicon-var-podium: "\f344";
$ionicon-var-pound: "\f219";
$ionicon-var-power: "\f2a9";
$ionicon-var-pricetag: "\f2aa";
$ionicon-var-pricetags: "\f2ab";
$ionicon-var-printer: "\f21a";
$ionicon-var-pull-request: "\f345";
$ionicon-var-qr-scanner: "\f346";
$ionicon-var-quote: "\f347";
$ionicon-var-radio-waves: "\f2ac";
$ionicon-var-record: "\f21b";
$ionicon-var-refresh: "\f21c";
$ionicon-var-reply: "\f21e";
$ionicon-var-reply-all: "\f21d";
$ionicon-var-ribbon-a: "\f348";
$ionicon-var-ribbon-b: "\f349";
$ionicon-var-sad: "\f34a";
$ionicon-var-sad-outline: "\f4d7";
$ionicon-var-scissors: "\f34b";
$ionicon-var-search: "\f21f";
$ionicon-var-settings: "\f2ad";
$ionicon-var-share: "\f220";
$ionicon-var-shuffle: "\f221";
$ionicon-var-skip-backward: "\f222";
$ionicon-var-skip-forward: "\f223";
$ionicon-var-social-android: "\f225";
$ionicon-var-social-android-outline: "\f224";
$ionicon-var-social-angular: "\f4d9";
$ionicon-var-social-angular-outline: "\f4d8";
$ionicon-var-social-apple: "\f227";
$ionicon-var-social-apple-outline: "\f226";
$ionicon-var-social-bitcoin: "\f2af";
$ionicon-var-social-bitcoin-outline: "\f2ae";
$ionicon-var-social-buffer: "\f229";
$ionicon-var-social-buffer-outline: "\f228";
$ionicon-var-social-chrome: "\f4db";
$ionicon-var-social-chrome-outline: "\f4da";
$ionicon-var-social-codepen: "\f4dd";
$ionicon-var-social-codepen-outline: "\f4dc";
$ionicon-var-social-css3: "\f4df";
$ionicon-var-social-css3-outline: "\f4de";
$ionicon-var-social-designernews: "\f22b";
$ionicon-var-social-designernews-outline: "\f22a";
$ionicon-var-social-dribbble: "\f22d";
$ionicon-var-social-dribbble-outline: "\f22c";
$ionicon-var-social-dropbox: "\f22f";
$ionicon-var-social-dropbox-outline: "\f22e";
$ionicon-var-social-euro: "\f4e1";
$ionicon-var-social-euro-outline: "\f4e0";
$ionicon-var-social-facebook: "\f231";
$ionicon-var-social-facebook-outline: "\f230";
$ionicon-var-social-foursquare: "\f34d";
$ionicon-var-social-foursquare-outline: "\f34c";
$ionicon-var-social-freebsd-devil: "\f2c4";
$ionicon-var-social-github: "\f233";
$ionicon-var-social-github-outline: "\f232";
$ionicon-var-social-google: "\f34f";
$ionicon-var-social-google-outline: "\f34e";
$ionicon-var-social-googleplus: "\f235";
$ionicon-var-social-googleplus-outline: "\f234";
$ionicon-var-social-hackernews: "\f237";
$ionicon-var-social-hackernews-outline: "\f236";
$ionicon-var-social-html5: "\f4e3";
$ionicon-var-social-html5-outline: "\f4e2";
$ionicon-var-social-instagram: "\f351";
$ionicon-var-social-instagram-outline: "\f350";
$ionicon-var-social-javascript: "\f4e5";
$ionicon-var-social-javascript-outline: "\f4e4";
$ionicon-var-social-linkedin: "\f239";
$ionicon-var-social-linkedin-outline: "\f238";
$ionicon-var-social-markdown: "\f4e6";
$ionicon-var-social-nodejs: "\f4e7";
$ionicon-var-social-octocat: "\f4e8";
$ionicon-var-social-pinterest: "\f2b1";
$ionicon-var-social-pinterest-outline: "\f2b0";
$ionicon-var-social-python: "\f4e9";
$ionicon-var-social-reddit: "\f23b";
$ionicon-var-social-reddit-outline: "\f23a";
$ionicon-var-social-rss: "\f23d";
$ionicon-var-social-rss-outline: "\f23c";
$ionicon-var-social-sass: "\f4ea";
$ionicon-var-social-skype: "\f23f";
$ionicon-var-social-skype-outline: "\f23e";
$ionicon-var-social-snapchat: "\f4ec";
$ionicon-var-social-snapchat-outline: "\f4eb";
$ionicon-var-social-tumblr: "\f241";
$ionicon-var-social-tumblr-outline: "\f240";
$ionicon-var-social-tux: "\f2c5";
$ionicon-var-social-twitch: "\f4ee";
$ionicon-var-social-twitch-outline: "\f4ed";
$ionicon-var-social-twitter: "\f243";
$ionicon-var-social-twitter-outline: "\f242";
$ionicon-var-social-usd: "\f353";
$ionicon-var-social-usd-outline: "\f352";
$ionicon-var-social-vimeo: "\f245";
$ionicon-var-social-vimeo-outline: "\f244";
$ionicon-var-social-whatsapp: "\f4f0";
$ionicon-var-social-whatsapp-outline: "\f4ef";
$ionicon-var-social-windows: "\f247";
$ionicon-var-social-windows-outline: "\f246";
$ionicon-var-social-wordpress: "\f249";
$ionicon-var-social-wordpress-outline: "\f248";
$ionicon-var-social-yahoo: "\f24b";
$ionicon-var-social-yahoo-outline: "\f24a";
$ionicon-var-social-yen: "\f4f2";
$ionicon-var-social-yen-outline: "\f4f1";
$ionicon-var-social-youtube: "\f24d";
$ionicon-var-social-youtube-outline: "\f24c";
$ionicon-var-soup-can: "\f4f4";
$ionicon-var-soup-can-outline: "\f4f3";
$ionicon-var-speakerphone: "\f2b2";
$ionicon-var-speedometer: "\f2b3";
$ionicon-var-spoon: "\f2b4";
$ionicon-var-star: "\f24e";
$ionicon-var-stats-bars: "\f2b5";
$ionicon-var-steam: "\f30b";
$ionicon-var-stop: "\f24f";
$ionicon-var-thermometer: "\f2b6";
$ionicon-var-thumbsdown: "\f250";
$ionicon-var-thumbsup: "\f251";
$ionicon-var-toggle: "\f355";
$ionicon-var-toggle-filled: "\f354";
$ionicon-var-transgender: "\f4f5";
$ionicon-var-trash-a: "\f252";
$ionicon-var-trash-b: "\f253";
$ionicon-var-trophy: "\f356";
$ionicon-var-tshirt: "\f4f7";
$ionicon-var-tshirt-outline: "\f4f6";
$ionicon-var-umbrella: "\f2b7";
$ionicon-var-university: "\f357";
$ionicon-var-unlocked: "\f254";
$ionicon-var-upload: "\f255";
$ionicon-var-usb: "\f2b8";
$ionicon-var-videocamera: "\f256";
$ionicon-var-volume-high: "\f257";
$ionicon-var-volume-low: "\f258";
$ionicon-var-volume-medium: "\f259";
$ionicon-var-volume-mute: "\f25a";
$ionicon-var-wand: "\f358";
$ionicon-var-waterdrop: "\f25b";
$ionicon-var-wifi: "\f25c";
$ionicon-var-wineglass: "\f2b9";
$ionicon-var-woman: "\f25d";
$ionicon-var-wrench: "\f2ba";
$ionicon-var-xbox: "\f30c";
