@import './scss/blink/framework/iblink-theme/variables';
@import './scss/custom';

// https://stackoverflow.com/a/53986985/6108211
a {
  text-decoration: none;
}
.recaptcha-required{
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  position: relative;
  top: -10px;
}
.grecaptcha-badge { 
  visibility: hidden;
}
.carousel.slick-slider {
  .slick-prev, .slick-next {
    background-color: $white-color;
    border-radius: 50%;
  }
  .slick-prev:before, .slick-next:before {
        color: var(--primary-color-lightest);
        font-size: 28px;
        opacity: 1;
        left: -4px;
        position: relative;
  }
}

.intro__slider.slider {
  width: 100%;
  height: 100%;
}

.gallery-footer-images {
  .slick-dotted.slick-slider {
    @media screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
  }
  .slick-dots {
    bottom: 40px;
    li button:before,
    li.slick-active button:before {
      color: transparent;
      opacity: 1;
    }
    li button:before{
      background-color: transparent;
      border: 4px solid $white-color;
      border-radius: 50%;
      display: inline-block;
      height: 20px;
      width: 20px;
  
    }
    li.slick-active button:before {
      background-color: $white-color;
    }
  }
}

.mat-mdc-checkbox-ripple {
  .mat-mdc-checkbox.cdk-focused & { opacity: 0.12; }
}