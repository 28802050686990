@import 'blink/framework/iblink-theme/variables';
@import "blink/framework/base-non-critical";
@import 'blink/verdors/ioniconons/scss/open-iconic-bootstrap';
@import 'blink/verdors/ioniconons/scss/ionicons';


bw-home,
bw-area {
  min-height: 600px;
}

// General settings
a:hover {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.modal.fade.show {
  background-color: $opacity-black1;
}

.form-group.has-icon.has-error:after {
  font-family: FontAwesome;
  content: "\F071";
  color: $primary-color-orange-light;
  position: absolute;
  right: 10px;
  bottom: 6px;
  font-size: 26px;
}

.form-group.has-icon.has-error.select:after {
  right: 36px !important;
}

.form-check.has-icon.has-error:after {
  font-family: FontAwesome;
  content: "\F071";
  color: $primary-color-orange-light;
  position: absolute;
  right: 10px;
  bottom: 0;
  font-size: 17px;
}

form input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid $gray3;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px -4px 0;
}

form input[type=checkbox]:checked + label:before,
form input[type=checkbox]:checked + input + label:before {
  font-family: FontAwesome;
  content: "\2713";
  background: $primary-color-orange;
  color: $white-color;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
}

.form-group {
  border: 1px solid $gray5;
  border-radius: 4px;

  .control-label {
    width: 100%;
    font-size: 11px;
  }

  .form-control,
  .form-control:focus {
    border: none;
  }


  &.form-group-disabled {
    background-color: $white3;
  }

  &.select {
    max-height: 55px;

    select {
      margin-top: -11px;
      padding-left: 12px;
    }
  }
}

.mat-mdc-form-field,
.form-group {
  .loading-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -8px;
  }
}
.modal-content .modal-footer {
  justify-content: center;
}

.modal-content .modal-body {
  text-align: center;
}

.modal .modal-header .close {
  color: $gray1;
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 34px;
  padding: 3px 0 0 1px;
  z-index: 2;
}

.modal-content .modal-footer button {
  margin: 20px 0;
}

@mixin transform($value) {
  -webkit-transform: translate3d($value, 0, 0);
  -moz-transform: translate3d($value, 0, 0);
  transform: translate3d($value, 0, 0);
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.container-fix.min-height-page {
  min-height: 100%;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.min-height-page.active {
  @include transform(260px);
}




/*----------FORMS STYLE----------*/

// .has-error input.ng-touched,
// .has-error.has-value input {
//     border: 2px solid red;
// }

/**
* Chrome autofill reset
**/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-background-clip: text;
  box-shadow: inset 0 0 20px 20px #23232329;
}

.form-group.label-floating {
  input {
    background: transparent;
  }
}

.has-error .form-group.label-floating {
  border: 1px solid $primary-color-orange;
  background-color: $white2;
}

.has-error {
  border: 1px solid $primary-color-orange !important;
  background-color: $white2;

  .form-control {
    background: transparent;
  }

  input {
    background: transparent;
  }
}

#terms-checkbox-container.has-error{
  border: 1px solid $primary-color-orange !important;
  background-color: $white2;
  padding-top: 10px;
  padding-left: 10px;
}

.has-error-checkbox {
  border: none;
  @extend .has-error;
}

.has-error .error-text {
  font-size: 11px;
  line-height: 12px;
  padding-top: 10px;
  color: $primary-color-orange-light;
  display: block;
  padding-bottom: 10px;
  position: absolute;
  bottom: -26px;
}

.btn:disabled,
.btn[disabled],
.btn.disabled {
  cursor: default;
}

.has-error .form-control-feedback,
.has-error .control-label {
  color: $opacity-gray1;
}

.error-label {
  font-size: 12px;
  line-height: 12px;
  padding: 5px 0;
  position: relative;
  top: -14px;
  color: $black1;
}

.error-text {
  display: none;
}


.mat-mdc-form-field-error {
  display: block;
  color: $error-color-alert
}

.error-notification {
  color: $red1;
  font-size: 16px;
}

.img-responsive {
  width: 100%;
}

.outstanding-balance {
  font-size: 20px;
  color: $primary-color-orange-light;
}

.payment-pop-up,
.password-pop-up,
.update-info-pop-up,
.pt-request-pop-up,
.thanks-info-pop-up,
.error-pop-up {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }

  .modal-header {
    padding-top: 50px;

    h5,
    h3 {
      color: $primary-color-orange;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      font-weight: 900;
      font-family: 'BlinkFitness_Balanced';
      font-size: 2em;
    }
  }

  .modal-body {
    padding-top: 5px;
    padding-bottom: 8px;
  }

  .modal-footer {
    padding-bottom: 30px;

    button {
      padding-left: 26px;
      padding-right: 26px;
    }
  }
}



.pay-required-pop {
  h5 {
    text-transform: capitalize !important;
  }
}

.navbar {
  height: 60px;
}

.blink-logo {
  width: 112px;
  height: 38px;
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.red-link {
  color: $primary-color-red;
}

.red-link:hover {
  color: $primary-color-red;
}

@media screen and (min-width: 1025px) {
  .blink-logo {
    margin-left: 40px;
    width: 90px;
    height: 31px;
  }
}

.menu-item {
  display: inline-block;
  font: 12px 'BlinkFitness_Balanced', sans-serif;
  color: $gray4;
  text-transform: uppercase;
  margin-right: 20px;
}

.promo-banner img {
  left: 50%;
  max-width: 900px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}


/* enable absolute positioning */

.inner-addon {
  position: relative;
}


/* style icon */

.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}


/* align icon */

.left-addon .glyphicon {
  left: 0px;
}

.right-addon .glyphicon {
  right: 0px;
}


/* add padding  */

.left-addon input {
  padding-left: 30px;
}

.right-addon input {
  padding-right: 30px;
}


/*----------REGISTRATION-BRIDGE----------*/

.container-registration-bridge {
  position: relative;

  h3 {
    font-family: 'BlinkFitness_Balanced', 'HelveticaNeue', Helvetica, Arial, sans-serif;
    color: $primary-color-red;
    text-align: center;
    font-size: 34px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  p.subtitle {
    margin: 0 auto;
  }

  .page-container-registration-bridge {
    margin: 60px auto;
    padding-top: 90px;
    padding-bottom: 60px;
    position: relative;

    &.zIndexDown {
      z-index: -2;
    }

    @media screen and (max-width: 1024px) {
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  @media (max-width: 320px) {
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  @media screen and (max-width: 1024px) {
    .page-container {
      margin-top: 0px !important;
    }

    p.subtitle {
      width: 290px;
    }
  }

  @media screen and (min-width: 1025px) {
    p.subtitle {
      width: 400px;
    }
  }

  @media screen and (max-width: 768px) {
    h3.title {

      b {
        display: block;

        @media screen and (max-width: 450px) {
          font-size: 30px;
        }
      }
    }
  }

  bw-loading-spinner {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.super-z-index {
      z-index: 10000 !important;
      height: 100%;
    }

    @media screen and (min-width: 1025px) {
      height: 773px;
    }

    .spinner {
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      &.visible {
        background: $opacity-gray4;
        opacity: 1;
      }

      &.hidden {
        background: transparent;
      }

      .sp {
        position: absolute;
        left: 50%;
        width: 50px;
        height: 50px;
        top: 50%;
        margin-left: -25px;
        opacity: 1;
        border-radius: 50%;
        background: transparent;
        box-shadow: 0px 2px 5px 1px $gray10;
      }
    }
  }
}

.nav-container {
  justify-content: flex-start;
}

.btn-clean {
  background: none;
  color: inherit;
  border: none;
  padding: 0 20px;
  font: inherit;
  cursor: pointer;
}

.btn-clean,
.btn-clean:focus {
  outline: none;
}


@media (max-width: 860px) {
  .remove-for-mobile {
    display: none;
  }
}

.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item).trial-link {
  cursor: pointer;
  font-size: 12px;
  color: $primary-color-navy;
}

.placeholder-gray {
  color: gray;
}

.btn-free-trial {
  margin-left: 33px;
}

.btn-free-trial-mobile {
  display: none;
}

@media screen and (max-width: 1025px) {
  .btn-free-trial {
    display: none;
  }

  .btn-free-trial-mobile {
    display: block;
    position: absolute;
    top: 22px;
    left: 5%;
    margin-left: 33px;
  }
}

@media screen and (max-width: 600px) {
  .btn-free-trial-mobile {
    font-size: 14px;
  }
}


.video-modal-container {
  max-width: 1000px;
}

@keyframes open {
  0% {
    overflow: visible;
  }

  99% {
    overflow: visible;
  }

  100% {
    overflow: hidden;
  }
}

@keyframes close {
  0% {
    overflow: hidden;
  }

  99% {
    overflow: hidden;
  }

  100% {
    overflow: visible;
  }
}

.map-location-clicked-tooltip {
  .comingsoon {
    color: $gray9;
    font-size: 14px;
    font-family: 'BlinkFitness_Balanced', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  }

  font-family: 'BlinkFitness_Balanced',
  'HelveticaNeue',
  Helvetica,
  Arial,
  sans-serif;
}

.map-location-clicked-tooltip .title h5,
.map-location-hover-tooltip .title h5 {
  font-family: 'BlinkFitness_Balanced', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  margin-right: 12px;
  color: $blue2;
  padding: 0;
  margin: 0;
}

.title h5 b a {
  color: $blue2;
}

.title h5 b a:hover {
  color: $blue3;
}

.map-location-clicked-tooltip .address {
  color: $gray4;
  margin-top: 10px;
}

.map-location-clicked-tooltip .goto a {
  font-family: 'BlinkFitness_Strong', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  color: $primary-color-red;
  font-size: 12px;
  text-transform: uppercase;
  padding-left: 0;
}

.modal.marketing-block-video {
  .modal-header .close {
    z-index: 100;
  }

  .modal-content .modal-header,
  .modal-content .modal-body {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .modal-content .modal-body {
    line-height: 0;

    video {
      width: 100%;
    }
  }

  .modal-content .modal-footer {
    display: none;
  }

  .modal-dialog {
    max-width: 800px;
  }
}

.mat-mdc-fab.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-mini-fab.mat-primary,
.mat-mdc-raised-button.mat-primary {
  background-color: $blue4;
}

.mat-mdc-checkbox-checked.mat-primary .mdc-checkbox__background,
.mat-mdc-checkbox-indeterminate.mat-primary .mdc-checkbox__background {
  background-color: $blue4 !important;
  border-color: transparent !important;
}

mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

mat-expansion-panel-header.mat-expansion-panel-header {
  padding: 0;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: $orange4;
}

/* bw-whyblink2 component does not exist currently d */
// bw-whyblink2 {
//   @extend bw-whyblink;

//   .whyblink-page-wrapper {
//     .why-blink-footer {
//       a {
//         color: $white-color;
//       }
//     }
//   }

//   .opti-header-why-blink h2 {
//     color: $white-color;
//     font-family: inherit;
//     font-weight: bold;
//     text-align: center;

//     &:first-child {
//       font-size: 2.8em;
//       padding: 30px 0 0 0;
//     }

//     &:nth-child(2) {
//       font-size: 2.4em;
//     }
//   }

//   .whyblink-page-wrapper {
//     .why-blink-footer {
//       h3 {
//         font-size: 1.2em;
//         margin-bottom: 15px;
//       }

//       a {
//         color: $black1;
//         font-size: 1.8em;
//         padding: 9px 71px;

//         &:after {
//           color: $white-color;
//           line-height: 60px;
//         }
//       }
//     }
//   }

//   .join-now-btn {
//     text-align: center;
//     padding: 7px;
//     min-width: 200px;
//     max-width: 329px;
//     margin: 0 auto;
//     height: 72px;

//     .join-new-center {
//       background: $orange5;
//       min-width: 190px;
//       max-width: 300px;
//       width: 100%;
//       box-sizing: border-box;
//       font-weight: bold;
//       font-size: 1.7em;
//       text-transform: uppercase;
//       position: relative;
//       margin: 0 auto;
//       display: table;
//       padding: 13px 33px;
//       background-color: $primary-color-orange;
//       color: $white-color;
//       transition: .25s cubic-bezier(.215, .61, .355, 1);
//       border: none;
//       border-top-left-radius: 4px;
//       border-bottom-left-radius: 4px;
//       -webkit-transform: translateX(-25px);
//       transform: translateX(-7px);
//       font-family: BlinkFitness_Balanced, sans-serif;

//       &:after {
//         content: "\f105";
//         font-family: FontAwesome;
//         background: $orange6;
//         padding: 7px 16px;
//         position: absolute;
//         top: 0;
//         right: -18px;
//         bottom: 0;
//         color: $white-color;
//         height: 100%;
//         line-height: 50px;
//         font-size: 22px;
//         width: 50px;
//       }
//     }
//   }

//   /*Header style changes*/
//   .opti-header-why-blink h2 {
//     color: $white-color;
//     font-family: inherit;
//     font-weight: bold;
//     text-align: center;
//   }

//   .opti-header-why-blink h2:first-child {
//     font-size: 2.8em;
//     padding: 30px 0 0 0;
//   }

//   .opti-header-why-blink h2:nth-child(2) {
//     font-size: 2.4em;
//   }

//   .join-now-btn {
//     text-align: center;
//     padding: 7px;
//     min-width: 200px;
//     max-width: 329px;
//     margin: 0 auto;
//     height: 72px;
//   }

//   .join-now-btn .join-new-center {
//     background: $orange5;
//     min-width: 190px;
//     max-width: 300px;
//     width: 100%;
//     box-sizing: border-box;
//     font-weight: bold;
//     font-size: 1.7em;
//     text-transform: uppercase;
//     position: relative;
//     margin: 0 auto;
//     display: table;
//     padding: 13px 33px;
//     background-color: $primary-color-orange;
//     color: $white-color;
//     transition: .25s cubic-bezier(.215, .61, .355, 1);
//     border: none;
//     border-top-left-radius: 4px;
//     border-bottom-left-radius: 4px;
//     -webkit-transform: translateX(-25px);
//     transform: translateX(-7px);
//     font-family: BlinkFitness_Balanced, sans-serif;
//   }

//   .join-now-btn .join-new-center:after {
//     content: "\f105";
//     font-family: FontAwesome;
//     background: $orange6;
//     padding: 7px 16px;
//     position: absolute;
//     top: 0;
//     right: -18px;
//     bottom: 0;
//     color: $white-color;
//     height: 100%;
//     line-height: 50px;
//     font-size: 22px;
//     width: 50px;
//   }

//   .col-12.bg-orange.error-wrapper {
//     width: 100%;
//     padding: 0;
//   }

//   .whyblink-page-wrapper .why-blink-footer a {
//     color: $white-color !important;
//   }

//   /*Mobile changes*/
//   @media (max-width: 1024px) {
//     #menu-left .menu-hidden {
//       height: 126px;
//     }

//     #menu-left .menu-hidden li {
//       display: none;
//     }

//     #menu-left .menu-hidden li:last-child {
//       display: block;
//     }

//     a.join-now-header {
//       display: none !important;
//     }

//     .col-12.bg-orange.error-wrapper {
//       margin-top: 0;
//     }
//   }

//   @media(min-width: 768px) and (max-width: 1024px) {
//     .opti-header-why-blink {
//       h2 {
//         &:first-child {
//           font-size: 2.1em;
//           padding: 90px 0 0 0;
//         }

//         &:nth-child(2) {
//           font-size: 1.7em;
//         }
//       }

//       &:nth-child(2) {
//         font-size: 1.2em;
//         margin: 0;
//       }
//     }

//     .col-12.bg-orange.error-wrapper {
//       margin: 0 !important;
//     }
//   }

//   @media(max-width: 767px) {
//     .opti-header-why-blink {
//       h2 {
//         line-height: 40px;

//         &:first-child {
//           font-size: 1.6em;
//           padding: 30px 0 0 0;
//           margin: 0;
//         }
//       }
//     }
//   }

//   @media(max-width: 600px) {
//     .opti-header-why-blink {
//       h2 {
//         line-height: 40px;

//         &:first-child {
//           font-size: 1.2em;
//           padding: 20px 0 0 0;
//         }

//         &:nth-child(2) {
//           font-size: 1em;
//         }
//       }
//     }
//   }
// }

//whyblink2 changes End
//Collapsible Menu
// #sidebarDesktop {
//   background-color: $gray4;
//   width: 311px;
//   padding: 42px 21px;
//   position: absolute;
//   top: $promo-banner-height;
//   transition: left 0.3s ease-in;

//   ul {
//     list-style: none;
//     margin: 30px 0;
//     padding: 0;

//     li {
//       padding: 2px 20px;

//       > a {
//         &.text-white {
//           font-size: 16px;
//           font-weight: bold;
//           cursor: pointer;

//           &:hover {
//             color: $primary-color-red !important;
//           }
//         }

//         &.red-link {
//           &:hover {
//             font-weight: bold;
//           }
//         }
//       }
//     }
//   }

//   &.collapsable-close {
//     left: -311px;
//   }

//   &.collapsable-open {
//     left: 0;
//   }

//   .collapse-close-icon {
//     position: absolute;
//     color: $white-color;
//     top: -12px;
//     right: 20px;
//     font-size: 58px;
//     cursor: pointer;
//     font-family: serif;

//     &:hover {
//       color: $primary-color-red;
//     }
//   }
// }

.club-bottom-details {
  .direction-link {
    color: $blue6;
    text-decoration: underline;
  }

  .loc-club-title {
    font-size: 2em;
    color: $gray11;
    font-weight: bold;
  }

  .loc-address {
    span {
      font-size: 12px;
    }
  }

  .link-holiday-hours {
    cursor: pointer;
  }
}

body {
  overflow-x: hidden;
}

.close-term-policy {
  color: $primary-color-orange;
  cursor: pointer;

  &:hover {
    color: $orange5;
  }
}

.modal {
  z-index: 9998;
}

@media screen and (max-width: 767px) {
  .hide-mobile{
    display:none;
  }
}

@media screen and (max-width: 1025px) and (min-width: 768px) {
  .hide-tablet{
    display:none;
  }
}

@media screen and (min-width: 1025px) {
  .hide-desktop{
    display:none;
  }
}

.mt-30 {
  margin-top:30px;
}

.mb-30 {
  margin-bottom:30px;
}

/*** Button with border ***/
button.btn-general,
a.btn-general {
  border-width: 1px;
  padding: 10px 35px;
  border-radius: 30px;

  &--fit-content {
    width: fit-content;
  }
  
  &--border_none {
    border-style: none;
  }

  &--border_solid {
    border-style: solid;
  }

  &--border_blue {
    border-color: $blue1;
  }

  &--border_gray {
    border-color: $gray2;
  }

  &--bg_white {
    background-color: $white-color;
  }

  &--bg_blue {
    background-color: $blue1;
  }
  
  &--bg_orange {
    background-color: $primary-color-orange-2;
  }

  &--bg_gray {
    background-color: $gray13;
  }

  &--font_white {
    color: $white-color;
  }

  &--font_blue {
    color: $blue1;
  }

  &--font_gray {
    color: $gray2;
  }

  &--font_light-gray {
    color: $gray1;
  }

  @media screen and (max-width: 767px) {
    &--mob-full-width {
      width: 100%;
    }
  }
}

/*** Form input style ***/

.main-cont-form {
  font-size: 1rem;

  .cont-form-group {
    margin-bottom: 3px;

    .mat-mdc-form-field {
      &:focus-within {
        .mat-mdc-text-field-wrapper {
          background-color: transparent;
          .mat-mdc-form-field-flex {
            border: 2px solid $blue1;
          }
        }
      }
      .mat-mdc-text-field-wrapper {
        background-color: transparent;
        padding: 0px;

        .mat-mdc-form-field-flex {
          background-color: $white-color;
          border: 1px solid $gray1;
          border-radius: 6px !important;
          padding: 0px 10px 0px;
          .mat-mdc-form-field-infix {

            .mat-mdc-select {
              font-size: 0.88rem;
              .mat-mdc-floating-label {
                color: $gray2 !important;
              }
              .mat-mdc-select-arrow {
                color: $gray1;
              }
              &.mat-mdc-select-invalid {
                .mat-mdc-select-arrow {
                  color: $gray1;
                }
              }

              + .loading-icon {
                right: 17px
              }
            }
              
            .mat-mdc-floating-label {
              font-size: 0.88rem;
            }

          }
        }
        .mat-mdc-form-field-subscript-wrapper {
          margin-top: 2px;
        }
        .mdc-line-ripple {
          display: none;
        }

        .mat-mdc-form-field-error {
          font-size: 0.69rem;
          color: $error-color-alert
        }
      }

      &.mat-form-field-invalid {
        .mat-mdc-text-field-wrapper {
          background-color: transparent;
          .mat-mdc-form-field-flex {
            border: 1px solid $error-color-alert;
          }
        }
        .mat-mdc-floating-label {
          color: $error-color-alert;
        }

        &.mat-focused {
          .mat-mdc-floating-label {
            color: $error-color-alert;
          }
        }
      }

      .mat-mdc-form-field-focus-overlay {
        display: none;
      }

      &.mat-focused {
        .mat-mdc-floating-label {
          color: $gray2;
        }
      }

      &.mat-form-field-disabled {
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex  {
          background-color: $white1;
        }
      }
    }

    .loading-icon {
      position: absolute;
      top: 25px;
      right: 17px
    }

    input {
      font-size: 0.88rem;

      + span label {
        color: $gray2;
      }
      &:focus {
        color: $blue1;
        + span label {
          color: $gray2;
        }
      }
    }

    &.has-icon.has-error-cont:after {
      font-family: "bootstrap-icons";
      content: "\F33A";
      color: $primary-color-orange-light;
      position: absolute;
      right: 25px;
      top: 7px;
      font-size: 26px;
    }
  }

  bw-terms-checkbox {
    margin: 0 0 25px!important;

    @media screen and (max-width: 767px) {
      margin-bottom: 10px !important;
    }
  }

  button.btn-green-color {
    width: 100%;
    border-radius: 30px;
    font-size: 1rem;
    height: auto;
    margin-bottom: 0;
    font-family: $font-family-bold;
  }

  .btn:disabled, .btn[disabled], .btn.disabled {
    opacity: 1;
  }

  + bw-terms-content {
    margin-top: 32px;
  }
}

/*** Combobox mat-select ***/
.cdk-overlay-container {
  z-index: 99999;
  .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: $blue1;
  }
  .mat-mdc-option {
    mat-pseudo-checkbox {
      display: none;
    }
    .mdc-list-item__primary-text {
      display: inline-block;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

mat-checkbox {
  .mdc-checkbox {
    .mdc-checkbox__background {
      border-color: $primary-color-blue-2 !important;
      border-radius: 4px;
    }
  }
  
  &.mat-mdc-checkbox-checked.mat-primary  .mdc-checkbox__background {
      background-color: $primary-color-blue-2;
      border-radius: 4px;

      .mdc-checkbox__checkmark {
        color: white !important;
      }
  }
  .checkbox-has-error .mdc-checkbox__background {
    border-color: $primary-color-red-2;
  }
}

/** dot logo **/
.dots {
  margin-bottom: 20px;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin: 0 3px;
  }
  &:before {
    background-color: $primary-color-orange-2;
  }
  &:after {
    background-color: $primary-color-blue-2;
  }
}


/* button close modal changes due to Bootstrap 5 */
button.close {
  border: none;
  background: none;
  font-weight: 700;
}


/**
* spinning class
*/

.blink-spin {
  -webkit-animation: blink-spin 2s infinite linear;
  animation: blink-spin 2s infinite linear;
}

@-webkit-keyframes blink-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes blink-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
