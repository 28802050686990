// Ionicons Font Path
// --------------------------

@font-face {
  font-family: $ionicons-font-family;
  src: url("#{$ionicons-font-path}/ionicons.eot?v=#{$ionicons-version}");
  src: url("#{$ionicons-font-path}/ionicons.eot?v=#{$ionicons-version}#iefix") format("embedded-opentype"),
    url("#{$ionicons-font-path}/ionicons.ttf?v=#{$ionicons-version}") format("truetype"),
    url("#{$ionicons-font-path}/ionicons.woff?v=#{$ionicons-version}") format("woff"),
    url("#{$ionicons-font-path}/ionicons.svg?v=#{$ionicons-version}#Ionicons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.ion {
  display: inline-block;
  font-family: $ionicons-font-family;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
