:root {
  --primary-color: #993100;
  --primary-color-darker: #662100;
  --primary-color-darkest: #802900;
  --primary-color-lighter: #b33900;
  --primary-color-lightest: #cc4100;
  --secondary-color: #0a5cd3;
  --secondary-color-darker: #001cd3;
  --secondary-color-darkest: #2400f4;
  --secondary-color-lighter: #1D6EDB;
  --secondary-color-lightest: #0289dd;
  --tertiary-color: #007352;
  --tertiary-color-darker: #005a40;
  --tertiary-color-darkest: #00402e;
  --tertiary-color-lighter: #008d64;
  --tertiary-color-lightest: #00a676;
  --subheader-text-color: #4b4f53;
  --paragraph-text-color: #000;
  --hyperlink-text-color: #095cd3;
  --hyperlink-text-visited-color: #095cd3;
  --error-text-color: #cc4100;
  --error-color: #cc4100;
  --error-color-lighter: lighten(<error-color>, 30%);
  --error-color-darker: darken(<error-color>, 20%);
  --warning-text-color: #FFB236;
  --warning-text-color-light: lighten(#FFB236, 6%);
  --warning-text-color-lighter: lighten(#FFB236, 20%);
  --warning-text-color-lightest: lighten(#FFB236, 70%);
  --alumni-blue-plan-color: #0a5cd3;
  --blue-plan-color: #0a5cd3;
  --gray-plan-color: #435362;
  --green-plan-color: #007352;
  --orange-plan-color: #993100;
}

@font-face {
  font-family: 'Primary_Balanced';
  src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.eot'); /* IE9 Compat Modes */
  src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.woff') format('woff'), /* Pretty Modern Browsers */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Primary_Lean';
  src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.eot'); /* IE9 Compat Modes */
  src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.woff') format('woff'), /* Pretty Modern Browsers */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Primary_Strong';
  src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.eot'); /* IE9 Compat Modes */
  src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.woff') format('woff'), /* Pretty Modern Browsers */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-display: swap;
}

.Primary_Strong {
  font-family: 'Primary_Strong', sans-serif;
}

.Primary_Lean {
  font-family: 'Primary_Lean', sans-serif;
}

.Primary_Balanced {
  font-family: 'Primary_Balanced', sans-serif;
}

body {
  &.a11y-theme {
    --primary-color: #993100;
    --primary-color-darker: #662100;
    --primary-color-darkest: #802900;
    --primary-color-lighter: #b33900;
    --primary-color-lightest: #cc4100;
    --secondary-color: #0a5cd3;
    --secondary-color-darker: #001cd3;
    --secondary-color-darkest: #2400f4;
    --secondary-color-lighter: #1D6EDB;
    --secondary-color-lightest: #0289dd;
    --tertiary-color: #007352;
    --tertiary-color-darker: #005a40;
    --tertiary-color-darkest: #00402e;
    --tertiary-color-lighter: #008d64;
    --tertiary-color-lightest: #00a676;
    --subheader-text-color: #4b4f53;
    --paragraph-text-color: #000;
    --hyperlink-text-color: #095cd3;
    --hyperlink-text-visited-color: #095cd3;
    --error-text-color: #cc4100;
    --error-color: #cc4100;
    --error-color-darker: darken(#FFB236, 20%);
    --warning-text-color: #FFB236;
    --warning-text-color-light: lighten(#FFB236, 6%) !default;
    --warning-text-color-lighter: lighten(#FFB236, 20%) !default;
    --warning-text-color-lightest: lighten(#FFB236, 70%) !default;
    --alumni-blue-plan-color: #0a5cd3;
    --blue-plan-color: #0a5cd3;
    --gray-plan-color: #435362;
    --green-plan-color: #007352;
    --orange-plan-color: #993100;


    @font-face {
      font-family: 'Primary_Balanced';
      src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.eot'); /* IE9 Compat Modes */
      src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.woff2') format('woff2'), /* Super Modern Browsers */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.woff') format('woff'), /* Pretty Modern Browsers */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.ttf') format('truetype'), /* Safari, Android, iOS */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-balanced.svg#svgFontName') format('svg'); /* Legacy iOS */
      font-display: swap;
    }

    @font-face {
      font-family: 'Primary_Lean';
      src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.eot'); /* IE9 Compat Modes */
      src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.woff2') format('woff2'), /* Super Modern Browsers */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.woff') format('woff'), /* Pretty Modern Browsers */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.ttf') format('truetype'), /* Safari, Android, iOS */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-lean.svg#svgFontName') format('svg'); /* Legacy iOS */
      font-display: swap;
    }

    @font-face {
      font-family: 'Primary_Strong';
      src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.eot'); /* IE9 Compat Modes */
      src: url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.woff2') format('woff2'), /* Super Modern Browsers */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.woff') format('woff'), /* Pretty Modern Browsers */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.ttf') format('truetype'), /* Safari, Android, iOS */
      url('https://fonts.assets.qa.blinkfitness.com/primary-font-strong.svg#svgFontName') format('svg'); /* Legacy iOS */
      font-display: swap;
    }

    .Primary_Strong {
      font-family: 'Primary_Strong', sans-serif;
    }

    .Primary_Lean {
      font-family: 'Primary_Lean', sans-serif;
    }

    .Primary_Balanced {
      font-family: 'Primary_Balanced', sans-serif;
    }
  }
}