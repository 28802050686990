@import '@angular/material/theming';

@keyframes roundbtnanim {
  0% {
    padding-left: 0px;
  }

  50% {
    padding-left: 10px;
  }

  100% {
    padding-left: 0px;
  }
}

@-webkit-keyframes roundbtnanim {
  0% {
    padding-left: 0px;
  }

  50% {
    padding-left: 10px;
  }

  100% {
    padding-left: 0px;
  }
}

bw-term-conditions-popup {
  .modal-body p {
    font-size: 14px;
    line-height: 18px;
  }
}

.error-email-exists {
  color: #5d6873;
  font-size: 16px;
}

.checkout-button {
  min-width: 300px !important;
  min-height: 50px !important;
}

.mat-mdc-card-header-text {
  margin: 0px !important;
}
