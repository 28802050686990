img {
  max-width: 100%;
  border-radius: 1px;
}

.img-raised {
  box-shadow: $box-shadow-raised;
}

.img-100 {
  width: 100%;
}
